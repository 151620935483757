exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-locations-tsx": () => import("./../../../src/pages/locations.tsx" /* webpackChunkName: "component---src-pages-locations-tsx" */),
  "component---src-pages-offer-tsx": () => import("./../../../src/pages/offer.tsx" /* webpackChunkName: "component---src-pages-offer-tsx" */),
  "component---src-pages-stock-apples-tsx": () => import("./../../../src/pages/stock/apples.tsx" /* webpackChunkName: "component---src-pages-stock-apples-tsx" */),
  "component---src-pages-stock-other-tsx": () => import("./../../../src/pages/stock/other.tsx" /* webpackChunkName: "component---src-pages-stock-other-tsx" */),
  "component---src-pages-stock-pears-tsx": () => import("./../../../src/pages/stock/pears.tsx" /* webpackChunkName: "component---src-pages-stock-pears-tsx" */),
  "component---src-pages-stock-potatoes-tsx": () => import("./../../../src/pages/stock/potatoes.tsx" /* webpackChunkName: "component---src-pages-stock-potatoes-tsx" */),
  "component---src-pages-stock-soft-fruits-tsx": () => import("./../../../src/pages/stock/soft-fruits.tsx" /* webpackChunkName: "component---src-pages-stock-soft-fruits-tsx" */),
  "component---src-pages-stock-tsx": () => import("./../../../src/pages/stock.tsx" /* webpackChunkName: "component---src-pages-stock-tsx" */)
}

